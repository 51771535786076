h2 {
    color: #004a96;
    display: inline-block;
    margin-bottom: 1.5rem;
}

h4 {
    margin-bottom: 15px;
}

button.btn.btn-primary {
    color: #fff;
    background-color: #004a96;
    border-color: #004a96;
}

.datatable-header-cell-label {
    font-weight: bold;
}

.ngx-datatable .datatable-body .datatable-body-row:hover,
.ngx-datatable .datatable-body .datatable-body-row:hover a {
    color: #FFF;
}

.ngx-datatable {
    box-shadow: none;
    font-size: 13px;
}

.ngx-datatable .datatable-header .datatable-header-cell, .ngx-datatable .datatable-body .datatable-body-row .datatable-body-cell {
    padding: 0.75rem;
    text-align: left;
    vertical-align: top;
}

.ngx-datatable .datatable-body .datatable-body-row {
    vertical-align: top;
    border-top: 1px solid #d1d4d7;
}

.ngx-datatable .datatable-header .datatable-header-cell {
    border-bottom: 1px solid #d1d4d7;
}

.datatable-body-row.datatable-row-even {
    background-color: rgba(0, 0, 0, 0.05);
}

.ng-valid[required], .ng-valid.required {
  border-left: 5px solid #42A948;
}

.ng-invalid:not(form) {
  border-left: 5px solid #a94442;
}

a {
    color: #004a96;
}

.highlight-row:hover {
    background-color: #f7f7f7;
    cursor: pointer;
}

.align-right {
    text-align: right;
}

.align-right icon-button {
    display: inline-flex;
    margin-left: 10px;
}

label {
  font-weight: 500;
  margin-bottom: 0.5rem;
}

input[type="radio"] {
    margin: 0 8px;
}

ngb-modal-backdrop {
  z-index: 1050 !important;
}

.form-group {
  margin-bottom: 15px;
}

.table-row:hover>td>a {
    color: white;
}


p+p:last-child {
  margin-bottom: 0;
}

/* Overwrite Bootstrap variables */

.list-group {
  --bs-list-group-active-bg: #004a96;
  --bs-list-group-active-border-color: #004a96;
}

.list-group a:hover {
  color: #004a96;
}
.horizontal-spacer {
    width: 100%;
}
